import axios, { setBaseUrl, baseURL, REGISTRY_URL } from './axios'

export function setBaseURL (url) {
  setBaseUrl(url)
  if (url !== '') getBaseUrlReady.resolve(true)
}

class Completer {
  constructor () {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }
}

export const getBaseUrlReady = new Completer()

export function getBaseURL (uuid, code, verifyCode, baseurl) {
  if (baseurl) return Promise.resolve(baseurl)
  return axios.get(`${REGISTRY_URL}/syy/frp/share/binding?uuid=${uuid}&shareCode=${code}`).then((resp) => {
    if (resp.code === 'A00200' && resp.data.length > 0) {
      return `https://${resp.data[0].address}/${uuid}`
    } else {
      throw resp
    }
  })
  // return Promise.resolve('https://20022.pntc.oriconas.com/d446ae2595cd022a1f08b25e51a67275')
}

export function getShareStatus (shareCode) {
  return axios.get(`/syy/v2/fs/share/code/info?shareCode=${shareCode}`)
}

export function cdDir (shareCode, verifyCode, pufi) {
  return axios.get(`/syy/v2/fs/share/object/list?page=1&pageSize=1000&shareCode=${shareCode}&verifyCode=${verifyCode}&pufi=${pufi}`)
}

export function analyticFolder (shareCode, verifyCode, pufi) {
  return axios.get(`/syy/v2/fs/share/dir/size?shareCode=${shareCode}&verifyCode=${verifyCode}&pufi=${pufi}`)
}

export function getDownloadUrl (shareCode, verifyCode, uuid) {
  return `${baseURL}/syy/v2/fs/share/objects?shareCode=${shareCode}&verifyCode=${verifyCode}&token=${uuid}`
}

export function getDownloadUuid (shareCode, verifyCode, ufis) {
  return axios.post(`/syy/v2/fs/share/objects/request?shareCode=${shareCode}&verifyCode=${verifyCode}`, { ufis: ufis })
}

export function getDeviceInfo () {
  return axios.get('/syy/siyouyun/device/info')
}
