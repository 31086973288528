import axios from 'axios'

// let IS_PROD = true

export var IS_PROD = true
// IS_PROD = false

export const REGISTRY_URL = IS_PROD ? 'https://registry.siyouyun.cc' : 'https://registry.pntc.siyouyun.cc'
export let baseURL = ''

const instance = axios.create()

instance.defaults.headers.post['Content-Type'] = 'application/json'

// 请求拦截器
instance.interceptors.request.use(function (config) {
  config.baseURL = baseURL
  return config
}, function (error) {
  return Promise.reject(error)
})

// 响应拦截器
instance.interceptors.response.use(function (response) {
  if (response.status >= 200 && response.status < 400) {
    return response.data
  } else {
    throw new Error('网络错误')
  }
}, function (error) {
  return Promise.reject(error)
})

export function setBaseUrl (url) {
  baseURL = url
}

export default instance
